import React from "react"

import { StaticImage } from "gatsby-plugin-image";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  carouselContainer,
  carouselInner,
  carouselItem,
  carouselImage,
} from './heroCarousel.module.css'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const HeroCarousel = (props) => (
  <div className={carouselContainer}>
    {/* <div className={carouselInner}> */}
      <Carousel
        responsive={responsive}
        // showDots={true}
        infinite={true}
        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        // sliderClass={}
        containerClass={carouselInner}
      >
        <div className={carouselItem}>
          <StaticImage
            objectFit="contain"
            objectPosition="center"
            layout="constrained"
            placeholder="blurred"
            className={carouselImage}
            src="../images/gallery/entrance_sideview.jpeg"
            alt="Penn Clinic Main Entrance"
          />
        </div>
        <div className={carouselItem}>
          <StaticImage
            objectFit="contain"
            objectPosition="center"
            layout="constrained"
            placeholder="blurred"
            className={carouselImage}
            src="../images/gallery/counter.jpeg"
            alt="Penn Clinic Counter"
          />
        </div>
        <div className={carouselItem}>
          <StaticImage
            objectFit="contain"
            objectPosition="center"
            layout="constrained"
            placeholder="blurred"
            className={carouselImage}
            src="../images/gallery/chair_close_shot.jpeg"
            alt="Penn Clinic Chairs From Waiting Area"
          />
        </div>
        <div className={carouselItem}>
          <StaticImage
            objectFit="contain"
            objectPosition="center"
            layout="constrained"
            placeholder="blurred"
            className={carouselImage}
            src="../images/gallery/chair_waiting_side.jpeg"
            alt="Penn Clinic Chairs From Consulting Room"
          />
        </div>
        <div className={carouselItem}>
          <StaticImage
            objectFit="contain"
            objectPosition="center"
            layout="constrained"
            placeholder="blurred"
            className={carouselImage}
            src="../images/gallery/consulting.jpeg"
            alt="Penn Clinic Consulting Room"
          />
        </div>
      </Carousel>
    {/* </div> */}
  </div>
)

export default HeroCarousel;