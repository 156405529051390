import React, { useEffect, useState } from 'react';
import pennMap from "../images/penn-map-mobile.jpeg";

const Map = () => {
  const [kakaoLoaded, setKakaoLoaded] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.kakao) {
      const { kakao } = window
      setKakaoLoaded(true);
      const container = document.getElementById('PennMap');
      const options = {
        center: new kakao.maps.LatLng(37.6, 127.14),
        level: 3
      };
      // 지도를 생성합니다.
      const map = new kakao.maps.Map(container, options);
      // 주소-좌표 변환 객체를 생성합니다.
      const geocoder = new kakao.maps.services.Geocoder();
      // 주소로 좌표를 검색합니다..
      geocoder.addressSearch('경기도 구리시 경춘로 253', function (result, status) {
  
        // 정상적으로 검색이 완료됐으면 
        if (status === kakao.maps.services.Status.OK) {
    
          var coords = new kakao.maps.LatLng(result[0].y, result[0].x);
    
          // 결과값으로 받은 위치를 마커로 표시합니다
          var marker = new kakao.maps.Marker({
            map: map,
            position: coords
          });
    
          // 인포윈도우로 장소에 대한 설명을 표시합니다
          var infowindow = new kakao.maps.InfoWindow({
            content: '<div style="color:#7b9acc;text-align:center;padding:6px 0;">펜실베니아 치과교정과</div>'
          });
          infowindow.open(map, marker);
    
          // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
          map.setCenter(coords);
        }
      })
    }
  }, []);

  return (
    <div id='PennMap'
    className="map-img"
    style={{
      width: '100%', 
      height: '100%'
    }}>
      {!kakaoLoaded ? (
        <picture>
          <img className="map-img" 
            src={pennMap}
            alt="펜실베니아 치과 약도"
          />
        </picture>
      ) : null}
    </div>
  );
}

export default Map;
