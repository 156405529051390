import React from "react"

import Layout from "../components/layout"
import Map from '../components/map'
import HeroCarousel from "../components/heroCarousel"
import indexContent from "../../site/editor/index.json"
import settings from "../../site/settings/settings.json"
import officeSchedule from "../../site/settings/office_schedule.json"

// Import images
import clinicOverview from '../images/gallery/chair_overview_green.jpeg'

const IndexPage = () => (
  <Layout>
    <div className="banner-wrapper">
      <div className="banner-title-wrapper">
        <h3 className="banner-title">{indexContent.heading}</h3>
      </div>
      <div className="banner-image-wrapper">
        <img src={clinicOverview} alt="치과 전경" className="banner-image" />
      </div>
    </div>
    <div className="intro-wrapper">
      <div className="profile">

      </div>
      <div className="summary-wrapper">
        <div className="summary">
          <h4 className="summary-title">구 본찬 원장</h4>
          <div dangerouslySetInnerHTML={{ __html: indexContent.about_dr_koo }}>
          </div>
          <div className="about-btn-wrapper">
            <a className="about-btn" href="/about">인사말</a>
          </div>
        </div>
      </div>
    </div>
    <div className="office-hours-schedule-wrapper">
      <div className="office-hours-wrapper">
        <h5>진료시간 안내</h5>
        <div>
          <p className="office-hrs-bullet">{officeSchedule.office_hrs.line_1}</p>
          <p className="office-hrs-bullet">{officeSchedule.office_hrs.line_2}</p>
          <p className="office-hrs-bullet">{officeSchedule.office_hrs.line_3}</p>
          <p className="office-hrs-bullet">{officeSchedule.office_hrs.line_4}</p>
          <p className="office-hrs-bullet">{officeSchedule.office_hrs.line_5}</p>
        </div>
      </div>
      <div className="office-holiday-wrapper">
        <h5>휴진 날짜 안내</h5>
        <div>
          <div className="office-holiday-month-wrapper office-holiday-this-month">
            <h6 className="office-holiday-month">
              {officeSchedule.office_holidays.this_month}: 
              <span> {officeSchedule.office_holidays.this_month_holidays}</span>
            </h6>
          </div>
          <div className="office-holiday-month-wrapper office-holiday-next-month">
            <h6 className="office-holiday-month">
              {officeSchedule.office_holidays.next_month}: 
              <span> {officeSchedule.office_holidays.next_month_holidays}</span>
            </h6>
          </div>
        </div>
      </div>
    </div>
   <HeroCarousel />
    <div className="guidance-wrapper">
      <div className="col-6 map-guide-wrapper">
        <div className="map-wrapper">
          <Map />
        </div>
        <div className="guide-wrapper">
          <h5 className="guide-title">교통편 안내</h5>
          <div className="guide-content-wrapper">
            <ul>
              <li>
                {settings.contact_info.address.street}
                {/* 주소: 경기도 구리시 경춘로 253  */}
                <br />
                {settings.contact_info.address.street_2}
                {/* (인창동, 다우스퀘어 빌딩) 7층 */}
              </li>
              <li><strong>구리역 도보 3분</strong></li>
              <li>
                롯데백화점, 구리역입구 버스 정류장 도보 2분
                <br />
                1-4, 3, 10-5, 23, 30, 65, 65-1, 165, 166-1, 167, 167-1,
                <br />
                1115-6. 1330-2, 1330-3, 1330-4, 8005, 8409, 330-1
              </li>
              <li><strong>건물 지상 및 지하주차장 완비</strong></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-6 building-wrapper">
        <div className="building">
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
